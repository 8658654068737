// Search

// Default
.components {
    &.search {

        margin: 36px 0;
        text-align: left;

        padding: 12px 16px 16px 16px;
        background-color: white;
        border-radius: var(--website-btn-radius);
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        color: var(--website-primary-color);

        .row {
            align-items: end;
            margin: 0;

            .field {
                padding: 0;
                padding-right: 15px;

                @include media-breakpoint-md {
                    padding-right: 15px;
                }

                label {
                    color: black !important;
                    font-size: 14px;
                    font-weight: 500;
                    padding-left: 2px;
                    margin: 0;
                }

                .form-group {
                    margin: 0;

                    .input-group {
                        position: relative;
                        z-index: 0;
                        margin: 4px 0 0 0;

                        input {
                            padding-right: 48px;
                            border-color: var(--website-primary-color);
                            color: currentColor;
                            font-weight: 500;
                            border-radius: var(--website-btn-radius);
                            font-family: var(--default-text-font-family);
                        }

                        .c-icon {
                            color: currentColor;
                            position: absolute;
                            top: 8px;
                            right: 8px;
                        }
                    }
                }
            }

            .btn{
                margin: 0;
            }

        }

        &.style-rounded {
            padding-top:16px;
            .row {
                .field {
                    label {
                        display: none;
                    }
                    .form-group {
                        .input-group {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        &.overlap{
            margin-top: 0;
            margin-bottom: 0;
            position: relative;
            z-index: 0;
            top: -35.5px;
        }

        &.full-with-sticky{
            margin-top: 0;
            margin-bottom: 0;
            position: fixed;
            z-index: 100;
            top:50px;
            @include media-breakpoint-md {
                top:74px;
            }
            right: 0;
            left: 0;
            border: 1px solid #ECECEC;
            border-width: 1px 0;
            box-shadow: none;
            border-radius: 0;
            background-color: white;
        }

    }
}
