// Block 41
@import "../../common/breakpoints";

section {
  &.block-41 {

    @include media-breakpoint-down-height-md {
      .pos-vertical-center {
        top: 0;
        transform: none;
        -webkit-transform: none;
      }

      &.height-100 {
        height: auto
      }
    }

    @include media-comb-one {
      &.height-100 {
        height: 100vh;
      }
    }

    @include media-comb-two {
      &.height-half {
        &.parallax {
          >.background-image-holder {
            min-height: 60vh;
          }
        }
      }
    }

    &.block-align-left {

      .img-container {
        display: inline-flex;
      }

      .c-btn-group {
        .btn {
          &.btn--secondary {
            margin-left: 0 !important;
          }

          @include media-breakpoint-md-down {
            + {
              .btn {
                margin-left: 0 !important;
              }
            }
          }
        }
      }

    }

    //Height Half 
    &.height-half {

      &.parallax {
        &.search-on {
          overflow: visible;
        }
      }

      .search-bar {
        bottom: 0;
        left: 0;
        right: 0;

        .components {
          &.search {
            position: absolute;
            top: -35.5px;
            left: 0;
            right: 0;
            margin: 0;
          }
        }
      }

    }

    .bg-video-audio {
      @media (max-width: 1024px) {
        display: none;
      }
      position: absolute;
      bottom: 20px;
      right: 20px;
      z-index: 4 !important;
      background-color: #fff;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      outline: none;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      [class^='material-icons'] {
        color: #000 !important;
      }
    }

  }
}