//
// Live Chat
// Bottom rith corner widget
//

.live-chat {
  .chat-box {
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
    z-index: 99;
    overflow: hidden;
    z-index: 2000;
    bottom: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;

    .chat-head {
      background: linear-gradient(135deg, rgb(2, 122, 255) 0%, rgb(0, 74, 155) 100%);
      min-height: 75px;
      color: white;

      .logo img {
        height: 40px;
      }

      a.close {
        &:hover {
          text-decoration: none;
        }
      }
    }

    .chat-body {
      background: #fff;
      flex-grow: 1;
      height: 0;

      .chat-form {
        height: 100%;

        .gc-outer-container-messages{
          height: 100% !important;
          .message-date {
            margin-top: 3.95px;
          }
        }

        .messages {
          flex: 1 1 auto;
          overflow-y: auto;

          .message:not(:first-child),
          .system-messages,
          .system-messages .collect-email {
            padding-top: 16px;
          }

          .from-support + .from-support,
          .from-customer + .from-customer {
            padding-top: 8px;
          }

          .from-customer {
            text-align: right;

            .msg {
              text-align: left;
              color: #fff;
            }
          }

          .msg {
            padding: 17px 20px;
            border-radius: 5px;
            max-width: 75%;
            color: rgb(0, 0, 0);
            background-color: rgb(245, 245, 245);
            display: inline-block;
            line-height: 1.4;
            font-size: 14px;
          }

          .system-messages {
            padding-left: 45px;
            .collect-email {
              align-items: end;
              margin: 0;
              position: relative;
              left: -60px;
              width: calc(100% + 60px);
              span.inbox {
                background-color: #888;
                border-radius: 50%;
                color: #fff;
                padding: 5px;
                font-size: 22px;
              }
              .form-email {
                position: relative;
                background-color: rgb(255, 255, 255);
                border-radius: 3px;
                font-size: 14px;
                line-height: 1.5;
                color: rgb(0, 0, 0);
                box-shadow: rgba(0, 0, 0,0.10) 0px 4px 15px 0px,
                             rgba(0, 0, 0, 0.10) 0px 1px 2px 0px,
                             rgba(0, 0, 0, 0.10) 0px 1px 2px 0px inset;
                transition: height 300ms ease-in-out 0s;
                padding: 26px 24px 24px;
                line-height: 1.5;
                .title{
                  font-weight: 600;
                  margin-bottom: 8px;
                }
                .email{
                  position: relative;
                  input{
                    width: 100%;
                    height: 40px;
                    padding: 11px 40px 11px 16px;
                    box-sizing: border-box;
                    font-size: 13px;
                    border: 1px solid rgb(225, 225, 225);
                    color: rgb(0, 0, 0);
                    border-radius: 4px;
                    background: rgb(250, 250, 250);
                    appearance: none;
                  }
                  button{
                    cursor: pointer;
                    z-index: 1;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    width: 40px;
                    height: 40px;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    //background-color: rgb(2, 122, 255);
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    user-select: none;
                    margin-top: 0;
                    border: 0;
                    svg{
                      position: absolute;
                      height: 15px;
                      width: 10px;
                      top: 12px;
                      right: 14px;
                      stroke: rgb(255, 255, 255);
                    }
                  }
                }
              }
            }
          }
        }

        .send{
          background-color: #fcfcfc;
          .btn--primary{
            cursor: pointer;
            background-color: #252525 !important;
            border-color: #252525 !important;
            border-radius: 5px !important;
            height: 38px;
            &:hover{
              background-color: #444444 !important;
              border-color: #444444 !important;
            }
            &:disabled{
              opacity: 1;
            }
          }
        }

      }
    }

    .chat-footer {
      &.d-none{
        display: none !important;
      }
      background: #fff;
      padding: 13px 16px;
      box-shadow: rgba(0, 0, 0, 0.04) 0px -10px 10px 1px;
      opacity: 0.97;

      svg {
        width: 24px;
        height: 16px;
        color: rgb(119, 118, 120);
        vertical-align: sub;
        padding-right: 8px;
      }

      img {
        width: 32px;
        padding-right: 8px;
        margin-bottom: 0;
      }
    }
  }

  .live-chat-icon {
    position: fixed;
    background: rgb(2, 122, 255);
    border-radius: 999rem;
    right: 20px;
    bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 32px 0px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 26px 13px 22px;
    color: #fff;
    transition: all 0.5s ease;

    svg {
      height: 20px;
      width: 20px;
      margin: 2px 2px 2px 4px;
      margin-right: 8px;
    }

    span.close {
      margin-right: 8px;
    }

    .material-icons {
      color: #fff;
    }

    &:hover {
      text-decoration: none;
    }

    &.up-from-btm{
      bottom: 79px;
    }

  }

  &.close {
    // close state
    .chat-box,
    .live-chat-icon .close {
      display: none;
    }
  }

  &.open {
    // open state
    .live-chat-icon .default {
      display: none;
    }
  }
}

@media only screen and (min-width: 993px) {
  .live-chat {
    .chat-box {
      width: 376px;
      min-height: 250px;
      max-height: 585px;
      right: 20px;
      bottom: 100px;
      border-radius: 8px;
      .chat-head {
        .close {
          display: none;
        }
      }
    }
  }
}