//
// jobs
//

@import '../../common/breakpoints';


.pages {
  &.jobs {

    .hero-banner {
      height: 300px;
      background-size: cover;
      background-position: center;
      position: relative;
      z-index: 0;
      display: flex;
      align-items: center;
      text-align: center;

      &::after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 1;
      }

      h1,
      p {
        position: relative;
        z-index: 1;
        color: white;
      }

      h1 {
        margin-bottom: 12px;
      }
    }

    .block-58 {
      .all {
        margin-top: 16px;
        margin-bottom: 32px;
      }
    }

    @import './job';

  }
}
