// Buttons

.btn {

    // Dynamic Colors
    &.b-d-primary { // button dynamic primary
        background-color: var(--website-primary-color);
        border: var(--website-primary-color);
    }

    // Dynamic border radius
    &.b-d-radius {
        border-radius: var(--website-btn-radius);
    }

    &.c-btn{
        color: white;
        border-radius: var(--website-btn-radius);
        background-color: var(--website-primary-color);
        border: var(--website-primary-color);
        padding-right: 1.85714286em;
        padding-left: 1.85714286em;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        @include media-breakpoint-md-down {
            width: 100%;
        }
    }

    &.c-btn-secondary{
      background-color: transparent;
      color: var(--website-primary-color);
      border-width: 1px;
      border-style: solid;

      &:hover {
        color: white;
        background-color: var(--website-primary-color);
        border-color: var(--website-primary-color);
      }
    }

}
