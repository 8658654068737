//
// Titles
//

// variables
$b-tiles-gap : 24px;

// styles
.b-titles {

  &.text-left{
    .textarea-edit{
      text-align: left !important;
    }
  }

  &:not(.text-left){
    text-align: center;
    justify-content: center;
  }
  
  .b-heading,
  .b-sub-heading {
    margin-bottom: $b-tiles-gap;
  }

  .b-heading+.b-sub-heading {
    margin-top: -($b-tiles-gap/2);
  }

  &.dc-mb-1{
    margin-bottom: 32px - $b-tiles-gap !important;
  }

}
