//
// block 57
//

.block-57{
    //items
    .items{
      display: flex;
      align-items: center;
      justify-content: center;
      .item{
        margin: 8px;
        svg{
          .social-svg-mask{
            transition: all .5s;
            opacity : 1;
            fill: var(--website-primary-color) !important;
          }
        }
        &:hover{
          .social-svg-mask{
            opacity : 0.7;
          }
        }
        button{
          padding: 0 !important;
          margin: 0 !important;
          height: auto !important;
        }
      }
    }
  }
  