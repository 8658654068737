//
// job
//

&.job {

  .job-container {

    padding: 54px 16px;
    @include media-breakpoint-md {
      padding: 110px 0 72px 0;
    }

    .description {
      h2 {
        margin: 0;
      }

      .content {
        padding: 36px 0;
      }
    }
  }
}
