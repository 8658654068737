//
// block 58
//

// mixins
@mixin block58_grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    padding: 20px;
    width: calc(100%/3 - 40px);
    @include media-breakpoint-md-down {
      width: 100%;
    }
    flex-direction: column;
    align-items: flex-start;

    .btn {
      margin-left: 0px;
      margin-top: 16px;
      width: 100%;
    }
  }
}

.block-58 {

  //items
  .items {
    .item {
      color: #666666;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);

      &:hover {
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.18);
        text-decoration: none;
      }

      background-color: white;
      padding: 32px;
      margin: 16px;
      display: flex;
      align-items: center;
      text-align: left;
      justify-content: space-between;

      .c1 {
        display: flex;
        flex-direction: column;
        width: 100%;

        .title {
          font-size: 18px;
          font-weight: 600;
        }

        .name {
          font-size: 16px;
          font-weight: 500;
        }
      }

      .btn {
        margin-left: 16px;
        line-height: normal;
      }

      &:hover {
        .btn {
          transform: translate3d(0, -2px, 0);
        }
      }
    }

    @include media-breakpoint-md-down {
      @include block58_grid;
    }

    // grid
    &.grid {
      @include block58_grid;
    }

  }

  .all {
    text-align: center;
    margin: 32px 16px 0 16px;

    .btn {
      @include media-breakpoint-md-down {
        margin-top: 0;
      }
    }
  }
}
