/*! Place your custom styles here */

* {
  /* Breaking long words */
  overflow-wrap: break-word;
}

.cursor-pointer {
  cursor: pointer;
}
.vh-100 {
  height: 100vh;
}

.h-100-p {
  height: 100%;
}
.h-25-px {
  height: 25px;
}
.fs-25-px {
  font-size: 25px;
}
.lh-25-px {
  line-height: 25px;
}
/**! starts : 05. Images **/
.image--lg {
  max-height: 9.28571429em;
}
.image--xl {
  max-height: 11.14285714em;
}
/**! end : 05. Images **/

/**! starts : 37. Spacing **/
.mb--05 {
  margin-bottom: 13px;
}
.mb--30px {
  margin-bottom: 30px;
}
.ptb-10px {
  padding-top: 10px;
  padding-bottom: 10px;
}
/**! end : 37. Spacing **/

/*! starts : -- Stack Dropdowns -- */
.s-header .h-bar.pos-fixed .d-header .dropdown .dropdown__content {
  filter: brightness(98%);
}
.s-header
  .h-bar.pos-fixed
  .d-header
  .dropdown
  .dropdown__content
  li.menu-item
  a
  span {
  filter: brightness(110%);
}
/*! end : -- Stack Dropdowns -- */

.event-banner a.btn {
  min-width: 200px;
}

.min-height-50 {
  min-height: 50vh;
}

.mh-100p {
  min-height: 100%;
}

/* text panel v1 starts */
section.text-panel-v1 .feature h4,
section.text-panel-v1 .feature h4 + p {
  max-width: 22.28571429rem;
}
@media all and (max-width: 767px) {
  section.text-panel-v1 .feature h4 {
    min-height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
/* text panel v1 end */

/* starts : Stack tabs  */
@media all and (max-width: 767px) {
  .m-tabs-full-width {
    width: 100%;
    float: none;
  }
}
/* Safari 7.1+ */
_::-webkit-full-page-media,
_:future,
:root .tabs--folder .tabs-content {
  bottom: 0;
}
/* end : Stack tabs  */

/* starts : Modal's adjustments */
@media all and (max-width: 767px) {
  .modal-content .boxed.boxed--lg {
    padding: 2.78571429em;
  }
}
/* end : Modal's adjustments */

section {
  position: relative;
}

a:hover .edit,
section:hover .edit-section,
.img-container:hover .edit,
.countdown-container:hover .edit,
.feature:hover .edit,
.col-md-4:hover .edit-image-info,
.process__item:hover .edit-delete-modal,
.agenda:hover .edit-add-agenda-item,
.flickity-slider:hover .edit-delete-image-item,
.image-info-item:hover .edit-delete-image-info-item,
.modal-instance:hover .video-play .edit-button-video,
.form-row-container-bg:hover .edit-button,
.form-container:hover .edit-form {
  display: block;
}

.edit {
  text-align: center;
  padding-top: 1px;
  padding-right: 0;
  position: absolute;
  right: -13px;
  top: -13px;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: none;
  z-index: 99;
}
.edit.sz-3 {
  height: 78px;
  width: 78px;
}
.edit {
  background-color: rgba(0, 0, 0, 0.2);
}
.edit:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.edit,
a.edit:hover {
  color: white;
}

/* edit v1, setting top and right positions to 10 */
.edit.v1 {
  right: 10px;
  top: 10px;
}

.edit a {
  color: white;
}

.edit-button,
.edit-button i.icon {
  color: white;
}

.edit-button-video {
  z-index: 90 !important;
}

.edit-img,
.edit-countdown {
  padding-top: 2px;
  padding-left: 2px;
}
.edit-countdown {
  top: 0;
}
.edit-img {
  right: calc(50% - 13px);
  top: calc(50% - 13px);
}
.edit.sz-3.edit-img {
  right: calc(50% - 39px);
  top: calc(50% - 39px);
  padding-top: 17px;
  padding-left: 0px;
}
.edit-delete-image-item {
  top: 0;
  right: 0;
  border-radius: 0;
  border-bottom-left-radius: 5px;
  color: white;
  padding-top: 2px;
}

.edit-image-info {
  right: 2px;
}

.edit-delete-image-item,
.edit-delete-image-info-item {
  background: #fa6160;
}

.edit-section {
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  border-radius: 0;
  border-bottom-left-radius: 20px;
  padding-top: 10px;
  padding-left: 4px;
  display: block;
}

.edit-form {
  top: 0px;
  left: 100%;
  height: 50px;
  width: 50px;
  border-radius: unset;
  padding-top: 8px;
}

.edit-order {
  top: 100px;
  right: 0;
  height: 50px;
  width: 50px;
  padding-top: 10px;
  padding-left: 4px;
  display: block;
}

.edit-order-down {
  top: 200px;
}

.edit-add-agenda-item {
  display: none;
}

.edit-delete-modal {
  right: 20px;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
}

.edit-button-form-row {
  border-radius: 0%;
  right: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.1);
}

.countdown-container {
  position: relative;
  z-index: 0;
}

.rdtSwitch {
  text-align: center;
}

.dow {
  text-align: center;
}

.rdtDay {
  text-align: center;
  font-size: 20px;
}

.rdtActive div {
  background-color: #888;
  color: white;
  height: 28px;
  width: 28px;
  padding-top: 2px;
  border-radius: 14px;
  text-align: center;
  margin: 0 auto;
}

.rdtDisabled {
  color: #e9e9e9;
}

.rdtTimeToggle {
  text-align: center;
  font-size: 20px;
}

.rdtCounter {
  float: left;
}

.rdtPrev span,
.rdtNext span {
  font-size: 30px;
  font-weight: 100;
}

.rdtNext span {
  float: right;
}

table th {
  background: transparent;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.btn-delete-block {
  color: #fa6160 !important;
  border-width: 0;
  padding: 0;
}

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.yt-video-container {
  width: 640px;
  height: 385px;
}

.yt-video-background {
  width: 100vh;
  height: 100vh;
}

.yt-container {
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
}

.yt-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* aspect ration for 16:9 */
  /*padding-top: 20px;*/ /* you can add padding-top if needed */
  height: 0;
  overflow: hidden;
}

.yt-wrapper .yt-mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.yt-wrapper iframe {
  position: absolute;
  top: -60px;
  height: calc(100% + 120px) !important;
  left: 0;
  width: 100%;
  height: 100%;
}

/* starts : common reuse, global classes */
.relative {
  position: relative;
  z-index: 0;
}
.d-inline-block {
  display: inline-block;
}
.d-block {
  display: block !important;
}
.min-width-auto {
  min-width: auto !important;
}
.width-auto {
  width: auto !important;
}
.section a.link-text {
  position: relative;
  z-index: 0;
  display: inline-block;
}
.section a.link-text .label {
  right: -64px;
  top: -23px;
}
.pre {
  white-space: pre-wrap !important;
}
.bt-0 {
  border-top: none !important;
}
.btr-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
/* end : common reuse, global classes */

/* starts : add-content-block-button */

.add-content-block {
}

.add-content-block + .add-content-block-button {
  position: relative;
}

.add-content-block + .add-content-block-button .button {
  position: absolute;
  left: calc(50% - 25px);
  line-height: 48px;
  z-index: 98;
  height: 52px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  text-align: center;
  bottom: -26px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-size: 44px;
  font-weight: 100;
  min-width: auto !important;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  padding: 0 10px;
  transition: all 0.5s;
}
.add-content-block-button .button span.default {
  position: relative;
  z-index: 0;
  top: -1px;
}
.add-content-block-button .button span.hover {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  white-space: nowrap;
  transition: all 0.5s;
  width: 0;
  overflow: hidden;
}
.add-content-block + .add-content-block-button .button:hover {
  background: rgba(0, 0, 0, 0.4);
  text-decoration: none;
  left: calc(50% - 99px);
}
.add-content-block + .add-content-block-button .button:hover span.hover {
  width: 141px;
  padding-left: 20px;
  padding-right: 20px;
}

/* end : add-content-block-button */

/* starts : render-preview-image */
.render-preview-image {
}

.render-preview-image .image-preview {
  margin-left: 0;
}

.logo-maker-preview,
.render-preview-image .image-preview .image {
  background-image: linear-gradient(45deg, #e5e5e5 25%, transparent 25%),
    linear-gradient(135deg, #e5e5e5 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #e5e5e5 75%),
    linear-gradient(135deg, transparent 75%, #e5e5e5 75%);
  background-size: 25px 25px;
  /* Must be a square */
  background-position: 0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px;
  /* Must be half of one side of the square */
  background-color: #f5f5f5;
  padding-left: 0;
  padding-right: 0;
  height: 100px;
  overflow: hidden;
}
.render-preview-image .image-preview .image .image-uploaded {
  max-height: 100%;
  width: auto;
}

.render-preview-image .image-preview .image-uploader .dropzone div {
  height: 100px;
}
.upload-my-logo .render-preview-image .image-preview .image {
  padding: 10px;
}
.image-preview.v2 {
}
.image-preview.v2 .image-uploader div,
.image-preview.v2 .image-uploader div[class$="-dropzone"] {
  overflow: visible;
}
.image-preview.v2 .image-uploader label {
  left: calc(-50px - 25%);
  padding-left: calc(50px + 25%);
}
/* end : render-preview-image */

/* starts : modal-container */
.modal-container .modal-content {
  max-height: 100%;
}
@media (max-width: 991px) {
  .modal-container .modal-content {
    width: 90% !important;
  }
}
/* end : modal-container */

/* starts : modal-side-menu */
.modal-container.modal-side-menu:before {
  background: none;
}
.modal-container.modal-side-menu .modal-content {
  top: 0;
  left: -430px;
  animation: slidefromright 0.3s forwards;
  background-color: white;
  transform: none;
  -webkit-box-shadow: 0 0 7px 1px rgba(45, 45, 45, 0.14);
  box-shadow: 0 0 7px 1px rgba(45, 45, 45, 0.14);
  width: 430px !important;
  height: 100%;
}
@keyframes slidefromright {
  100% {
    left: 0;
  }
}
.modal-container.modal-side-menu .modal-content:not(.from-left) {
  left: auto;
  right: -430px;
  animation: slidefromleft 0.3s forwards;
}
@keyframes slidefromleft {
  100% {
    right: 0;
  }
}
.modal-container.modal-side-menu.side-menu-close .modal-content {
  animation: slideMenuCloseToRight 0.3s forwards;
  left: 0;
}
@keyframes slideMenuCloseToRight {
  100% {
    left: -430px;
  }
}
.modal-container.modal-side-menu.side-menu-close
  .modal-content:not(.from-left) {
  animation: slideMenuCloseToLeft 0.3s forwards;
  left: auto;
  right: 0;
}
@keyframes slideMenuCloseToLeft {
  100% {
    right: -430px;
  }
}
.modal-container.modal-side-menu a.close {
  width: 30px;
  height: 30px;
  color: gray;
  cursor: pointer;
  background-image: url("../icons/delete.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: absolute;
  right: 34px;
  top: 50px;
  z-index: 1;
}
.modal-container.modal-side-menu .sm-settings {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  padding-left: 20px;
  padding-right: 20px;
}
.modal-container.modal-side-menu .boxed.sm-box {
  border-color: #333333 !important;
}
.modal-container.modal-side-menu .boxed.sm-box,
.modal-container.modal-side-menu .boxed.sm-box > *,
.modal-container.modal-side-menu .Collapsible .Collapsible__trigger,
.modal-container.modal-side-menu .boxed.sm-box h1,
.modal-container.modal-side-menu .boxed.sm-box h2,
.modal-container.modal-side-menu .boxed.sm-box h3,
.modal-container.modal-side-menu .boxed.sm-box h4,
.modal-container.modal-side-menu .boxed.sm-box h5,
.modal-container.modal-side-menu .boxed.sm-box h6,
.modal-container.modal-side-menu .boxed.sm-box .h1,
.modal-container.modal-side-menu .boxed.sm-box .h2,
.modal-container.modal-side-menu .boxed.sm-box .h3,
.modal-container.modal-side-menu .boxed.sm-box .h4,
.modal-container.modal-side-menu .boxed.sm-box .h5,
.modal-container.modal-side-menu .boxed.sm-box .h6,
.modal-container.modal-side-menu .boxed.sm-box select,
.modal-container.modal-side-menu .boxed.sm-box input {
  color: #333333 !important;
  /* color: red !important; */
}
.modal-container.modal-side-menu .Collapsible .Collapsible__trigger {
  display: block;
  opacity: 1;
  /* background-color: red; */
}
.modal-container.modal-side-menu
  .Collapsible
  .Collapsible
  .Collapsible__trigger {
  /* display: inline-block; */
}
.modal-container.modal-side-menu .Collapsible .Collapsible {
  position: relative;
  z-index: 0;
}
.modal-container.modal-side-menu
  .Collapsible
  .Collapsible
  .delete-item-collapsible {
  top: 13px;
}
.modal-container.modal-side-menu
  .Collapsible
  .Collapsible__trigger.is-closed
  + .Collapsible__contentOuter
  .delete-item {
  display: none;
}
.modal-container.modal-side-menu .sm-color-picker {
  padding-right: 32px;
}
.modal-container.modal-side-menu .sm-color-picker a.color,
.modal-container.modal-side-menu .sm-color-picker a.primary-color-swatch {
  margin-bottom: 5px;
  display: inline-block;
  float: left;
}
.modal-container.modal-side-menu .sm-color-picker a.primary-color-swatch label {
  margin-top: 2px;
}
.modal-container.modal-side-menu
  .sm-color-picker
  a.primary-color-swatch
  div.color {
  border-radius: 50% !important;
}
.modal-container.modal-side-menu .sm-color-picker .color-box-value {
  min-width: 138px !important;
}
.modal-container.modal-side-menu .sm-color-picker.logo-maker .color-box-value {
  min-width: 174px !important;
}
.modal-container.modal-side-menu .tile-img {
  line-height: 1.3;
}
.modal-container.modal-side-menu .image-search-thumbnail-text {
  white-space: normal;
  line-height: 1.3;
}
.modal-container.modal-side-menu .boxed.sm-box {
  background-color: transparent !important;
  padding: 0;
  border-width: 0 !important;
}
.modal-container.modal-side-menu .boxed.sm-box .Collapsible {
  background-color: white;
}
.modal-container.modal-side-menu .boxed.sm-box .Collapsible__trigger {
  background-color: #e6e5e6;
  border-radius: 10px;
  padding: 18px 80px 20px 52px;
  background-position: 16px 16px;
  border: 1px solid #e6e5e6;
  border-bottom-width: 0;
}
.modal-container.modal-side-menu .boxed.sm-box .Collapsible__trigger.is-open {
  background-color: #fff;
  padding-bottom: 6px;
  border-color: #333;
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
/* .modal-container.modal-side-menu .Collapsible .Collapsible__trigger.is-closed{
  color: #fff !important;
  background-image: url(/assets/icons/right-arrow-white.png);
} */
.modal-container.modal-side-menu .boxed.sm-box .Collapsible__contentOuter {
  padding: 0 16px 16px 16px;
  border: 1px solid #333;
  border-top-width: 0;
}
.modal-container.modal-side-menu .Collapsible .Collapsible {
  margin-bottom: 30px;
}
.modal-container.modal-side-menu
  .Collapsible
  .Collapsible
  .Collapsible__contentInner
  > .boxed {
  margin-top: 0;
  padding-top: 20px;
}
.modal-container.modal-side-menu
  .Collapsible
  .Collapsible
  .Collapsible__trigger.is-closed
  + .delete-item-collapsible
  + .Collapsible__contentOuter {
  padding-bottom: 0;
  border-bottom: 0;
}
.modal-container.modal-side-menu
  .boxed.sm-box
  .Collapsible__trigger.is-closed
  + .Collapsible__contentOuter {
  padding-bottom: 0;
  border-width: 0;
}
.modal-container.modal-side-menu
  .boxed.sm-box
  .Collapsible__trigger.is-open
  + .Collapsible__contentOuter {
  border-top-width: 0;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.modal-container.modal-side-menu .boxed.sm-settings .modal-share {
  margin: 0;
}
.modal-container.modal-side-menu .boxed.sm-settings .modal-share .modal-body {
  padding-left: 0;
  padding-right: 0;
}
/* starts : common use spacing classes */
.m-0 {
  margin: 0 !important;
}
.m-t-12 {
  margin-top: 12px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-100 {
  margin-top: 100px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-b-100 {
  margin-bottom: 100px;
}
.m-b-6 {
  margin-bottom: 6px;
}
.icon--sm.v2 {
  font-size: 1.885714288em;
}
i.stack-trash {
  font-size: 14px;
  padding-right: 4px;
}
a.dtb {
  /* to get the default text behaviour */
  color: #666666;
  text-decoration: none;
}
.fs-12 {
  font-size: 12px;
}
.fs-18 {
  font-size: 18px;
}
.lh-44 {
  line-height: 44px;
}
.fw-1 {
  font-weight: 100;
}
.fw-4 {
  font-weight: 400;
}
.fw-7 {
  font-weight: 700;
}

/* starts : common Collapsible */
.Collapsible .Collapsible__trigger,
.Collapsible .Collapsible__contentOuter,
.Collapsible .Collapsible__contentOuter * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}

.Collapsible .Collapsible__trigger {
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  opacity: 0.5;
  cursor: pointer;
  color: #252525;
  font-weight: 600;
}

.Collapsible .Collapsible__trigger:hover,
.Collapsible .Collapsible__trigger.is-open {
  opacity: 1;
}

.Collapsible .Collapsible__trigger.is-closed:before,
.Collapsible .Collapsible__trigger.is-open:before {
  font-family: "stack-interface";
  content: "\e80d";
  margin-right: 6px;
  color: #252525;
}

.Collapsible .Collapsible__trigger.is-open:before {
  content: "\e80a";
}

.Collapsible .Collapsible__trigger {
  position: relative;
  z-index: 0;
  padding: 2px 64px 6px 36px;
  background-image: url(/assets/icons/right-arrow.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 26px auto;
  display: inline-block;
  line-height: normal;
}
.Collapsible .Collapsible__trigger.is-open {
  background-image: url(/assets/icons/down-arrow.png);
}
.Collapsible .Collapsible__trigger.is-closed:before,
.Collapsible .Collapsible__trigger.is-open:before {
  display: none;
}
/* end : common Collapsible */

.is-open + .Collapsible__contentOuter {
  overflow: visible !important;
}

.Collapsible__contentInner > .boxed {
  margin-top: 20px;
}

.modal-content > div.boxed {
  overflow: visible;
}

/* end : common use spacing classes */

/* starts : Header classes */
@media (min-width: 991px) {
  .pos-fixed {
    z-index: 100;
  }
}
.secondary-nav-bar:not(.header-off) {
  min-height: 90px;
}
.secondary-nav-bar.site-wide-notice-on {
  min-height: 126px;
}
.secondary-nav-bar.site-wide-notice-on.header-off {
  min-height: 45px;
}
/* end : Header classes */

/* starts : textarea edit classes */
.textarea-edit {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.textarea-edit-modal:hover {
  /*
  Uncomment to stop this textarea from reacting to hover
  transform: unset;
  background-color: unset
  */
}
.radial .textarea-edit.radial__label:hover {
  transform: translateX(-50%) translateY(-50%) scale(1.05);
  -webkit-transform: translateX(-50%) translateY(-50%) scale(1.05);
}
/* end : textarea edit classes */

/* starts : twitter-picker classes */
.twitter-picker {
  border: 0 !important;
  box-shadow: unset !important;
  width: 100% !important;
}

.twitter-picker div span div {
  border: 1px solid #d3d3d3;
}
/* end : twitter-picker classes */

/* starts : edit-form row section classes */
.form-row {
  position: relative;
  z-index: 0;
}
/* end : edit-form row section classes */

/* starts : add-form-row section classes */

.add-form-row {
  margin: 8px 0;
  transition: all 0.5s ease;
  border: 1px solid #fff;
  padding: 3.25px;
  border-radius: 6px;
  -webkit-appearance: none;
  cursor: pointer;
}

.add-form-row .button {
  display: inline-block;
  line-height: 26px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  min-width: auto !important;
  color: #888 !important;
}

.add-form-row:hover {
  background: #fcfcfc;
  border-color: #ececec;
}

.add-form-row:hover .button {
  font-weight: 100;
  text-decoration: none;
  line-height: 32px;
  width: 32px;
  height: 32px;
  font-size: 32px;
  z-index: 0;
}

/* .render-inputs .accordion-2 li .accordion__title,
.render-inputs .accordion li:last-child .accordion__title {
  border:0;
}
.render-inputs .accordion li.active .accordion__content {
  max-height: none;
}
.render-inputs .accordion .accordion__title{
  margin-bottom: 0;
} */
/* end : add-form-row section classes */

/* starts : btn-add */
.btn.btn-add {
  border: 0;
  padding-left: 42px;
  padding-right: 8px;
  position: relative;
  z-index: 0;
}
.btn.btn-add .btn__text {
  font-weight: 300;
}
.btn.btn-add i {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  text-align: center;
  font-size: 22px;
  line-height: 20px;
  min-width: auto !important;
  color: #252525 !important;
  position: absolute;
  left: 8px;
  top: 6px;
  background: transparent;
  font-weight: 100;
  color: #252525 !important;
  border: 1px solid #252525;
}

.btn.btn-add:hover i {
  text-decoration: none;
}
/* end : btn-add */

/* starts : custom input group */
.input-icon.input-icon--right i {
  left: auto;
  right: 1px;
  width: 40px;
  top: 0;
  font-style: normal;
  border-left: 1px solid #ececec;
  text-align: center;
  font-weight: 100;
  background: #eeeeee;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
/* end : custom input group */

.animating {
  z-index: 1 !important;
}
.animating-focus::after {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4) !important;
  z-index: 2 !important;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.5;
  -webkit-animation-duration: 400ms;
  animation-duration: 400ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.form-container {
  overflow: visible;
}

/* starts : section settings */
.section .settings {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  width: 50px;
  z-index: 3;
}

.section .settings .buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.section .settings .buttons a:hover,
.nav-button-edit:hover {
  text-decoration: none;
}

.section .settings .buttons .button-edit {
  margin: 20px 0;
}

.section .settings .buttons .button-delete {
  margin-bottom: 20px;
}

.section .settings .buttons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-weight: 100;
  min-width: auto !important;
  color: #ffffff !important;
}

.section .settings .buttons a:hover {
  background: rgba(0, 0, 0, 0.4);
}

.section .settings .buttons a {
  font-size: 20px;
}

.m-icon-s1 {
  font-size: 32px;
}
.m-icon-s2 {
  font-size: 28px;
}

.c-white-opa-8 {
  color: rgba(255, 255, 255, 0.8);
}

.section .settings .buttons .button-edit a,
.section .settings .buttons .button-delete a {
  width: 50px;
  height: 50px;
}

.button-slide {
  width: 50px;
  height: 50px;
  position: relative;
  z-index: 0;
}
.section .settings .buttons .button-slide a {
  font-size: 14px;
  position: absolute;
  right: 0;
  width: auto !important;
  border-radius: 50px;
  padding: 0 9px 0 9px;
}
.button-slide .slide-text {
  white-space: nowrap;
  transition: all 0.5s;
  overflow: hidden;
  width: 0;
  font-weight: 600;
  line-height: 1;
  margin-top: 1px;
}
.button-slide:hover .slide-text {
  width: 174px;
  padding-left: 24px;
  padding-right: 18px;
}

.button-slide.button-move {
  width: 35px;
  height: 35px;
}
.section .settings .buttons .button-slide.button-move a {
  padding: 0 3.5px;
  background-image: none;
}
.button-slide.button-move.down i {
  margin-top: 2px;
}
.button-slide.button-move:hover .slide-text {
  width: 137px;
  padding-right: 14px;
  padding-left: 24px;
}

.button-slide.button-move.down:hover .slide-text {
  width: 157px;
}

/* end : section settings */

.content-block-container {
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 20px;
}
.content-block-image {
  box-shadow: 0px 0px 20px 9px rgba(0, 0, 0, 0.1);
}

.nav-button-edit-container {
  background-color: transparent;
}

.nav-button-edit {
  position: absolute;
  right: 20px;
  top: calc(50% - 25px);
  z-index: 100;
}
.nav-button-edit a {
  font-size: 14px;
  position: absolute;
  right: 0;
  width: auto !important;
  border-radius: 50px;
  padding: 0 9px 0 9px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-weight: 100;
  min-width: auto !important;
  color: #ffffff !important;
}

.nav-button-edit a:hover {
  background: rgba(0, 0, 0, 0.4);
  text-decoration: none;
}
.nav-button-edit .button-slide:hover .slide-text {
  width: 106px;
}
.footer-7,
.footer-2 {
  position: relative;
}

.icon-select {
  min-height: 600px;
}

.modal-content {
  width: 700px !important;
}

.dzu-dropzone {
  border: none !important;
  background-color: #f9f9f9;
  min-height: unset !important;
  margin: 0px !important;
  width: unset !important;
}

.dzu-inputLabel {
  color: #b9b9b9 !important;
  font-weight: 400 !important;
  text-align: center !important;
}

div.twitter-picker div {
  padding-left: 0px !important;
}

.icon-item {
  margin-bottom: 40px;
}

.icon-item i {
  color: #999;
}

.icon-item:hover i {
  color: black;
  transform: scale(1.05);
}

.icon-item h5 {
  margin-top: 10px;
}

#notification-container {
  position: fixed;
  top: 200px;
  left: 20%;
  right: 20%;
  z-index: 999;
  display: none;
  pointer-events: none;
}

#notification-container #notification-content {
  position: relative;
  margin: 0 auto !important;
  width: 400px !important;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  color: white;
  font-size: 28px;
  line-height: 52px;
}

#notification-container #notification-content.error {
  background: rgba(255, 0, 0, 1) !important;
}

.pointer-events-unset {
  pointer-events: auto !important;
  cursor: pointer;
}

.form-row-container {
  position: relative;
  padding: 20px;
  margin-left: -20px;
  margin-right: -20px;
}

.form-row-container-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.form-row-container-bg:hover.enabled,
.form-field:hover.enabled {
  background-color: rgba(0, 0, 0, 0.03);
  transition: background-color 0.2s ease;
}

.form-field:hover.enabled {
  background-color: rgba(0, 0, 0, 0.05);
}

.add-form-row {
  min-height: 60px !important;
}

.btn-video {
  border: none;
}
.btn-video .btn__text {
  font-size: 1em;
  font-weight: 400;
}

/* starts, block-01 classes */
.section.block-01 .countdown {
  margin-bottom: 0.59090909090909em;
}
/* end, block-01 classes */

/* starts, block-02 classes */
.section.block-02 .countdown {
  margin-bottom: 0.59090909090909em;
}
/* end, block-02 classes */

/* starts, block-10 classes */
.section.block-10 h4,
.section.block-10 .textarea-edit.h4 {
  margin-top: 1.36842105263158em;
}
.section.block-10 .feature .textarea-edit.h4 {
  margin-bottom: 0.342105263157895em;
}
.section.block-10 .feature .textarea-edit.p {
  font-size: 1em;
}
.section.block-10 .feature .textarea-edit.p + a:not(.btn) {
  margin-top: 1.85714286em;
  font-size: 0.85714286em;
  line-height: 2.16666667em;
}
/* end, block-10 classes */

/* starts, block-12 classes */
.section.editable-block.block-12 .textarea-edit.h4 {
  margin-bottom: 0.342105263157895em;
}
/* end, block-12 classes */

/* starts, block-18 classes */
.section.block-18 .bg-img-rounded,
.article-item .bg-img-rounded {
  height: 234px;
  width: 100%;
  object-fit: cover;
}
.section.editable-block.block-18 .bg-img-rounded {
  margin-bottom: 0;
}
/* end, block-18 classes */

/* starts, section_20 classes */
.section .section_20 .textarea-edit {
  margin-bottom: 0 !important;
}

.section .section_20 .editable-span {
  bottom: auto;
  text-align: left;
}

.section .section_20 span.editable-span {
  width: auto;
  flex: 0 0 auto;
}
@media (min-width: 768px) {
  .section .section_20 .textarea-edit {
    margin-left: 13px;
  }
}
/* end, section_20 classes */

/* starts, section_21 classes */
.section .section_21 .textarea-edit {
  margin-bottom: 0 !important;
  position: relative;
  z-index: 0;
  top: -3px;
}

.section .section_21 h2 {
  bottom: auto;
  position: relative;
  z-index: 0;
  top: -2px;
  text-align: left;
}

.section .section_21 h2.col-md-5 {
  width: auto;
  flex: 0 0 auto;
}

/* end, section_21 classes */

/* starts, block-23 classes */
.section.block-23 .slider .slide {
  height: 230px;
  width: 100%;
  background: url() center no-repeat;
  background-size: cover;
}
@media (min-width: 576px) {
  /* bootstrap break point 01 */
  .section.block-23 .slider .slide {
    height: 340px;
  }
}
@media (min-width: 768px) {
  /* bootstrap break point 02 */
  .section.block-23 .slider .slide {
    height: 690px;
  }
}
@media (min-width: 992px) {
  /* bootstrap break point 03 */
}
@media (min-width: 1200px) {
  /* bootstrap break point 04 */
  .section.block-23 .slider .slide {
    height: 740px;
  }
}
/* end, block-23 classes */

/* starts, block-24 classes */
.section.block-24 .slide a,
.gallery .slide a {
  height: 95px;
  width: 100%;
  background: url() center no-repeat;
  background-size: cover;
  display: block;
}
@media (min-width: 576px) {
  /* bootstrap break point 01 */
  .section.block-24 .slide a,
  .gallery .slide a {
    height: 150px;
  }
}
@media (min-width: 768px) {
  /* bootstrap break point 02 */
  .section.block-24 .slide a,
  .gallery .slide a {
    height: 133px;
  }
}
@media (min-width: 992px) {
  /* bootstrap break point 03 */
  .section.block-24 .slide a,
  .gallery .slide a {
    height: 186px;
  }
}
@media (min-width: 1200px) {
  /* bootstrap break point 04 */
  .section.block-24 .slide a,
  .gallery .slide a {
    height: 226px;
  }
}
/* end, block-24 classes */

/* starts, block-25 classes */
.section.block-25 .bg-img-rounded {
  border-radius: 100%;
  width: 190px;
  height: 190px;
  margin: 0 auto 1.85714286em auto;
  background: url() center no-repeat;
  background-size: cover;
}
@media (min-width: 1200px) {
  /* bootstrap break point 04 */
  .section.block-25 .bg-img-rounded {
    width: 208px;
    height: 208px;
  }
}
/* end, block-25 classes */

/* starts, block-26 classes */
.section.block-26 .border--round {
  width: 100%;
  height: 234px;
  margin: 0 auto 1.85714286em auto;
  background: url() center no-repeat;
  background-size: cover;
}
@media (min-width: 576px) {
  /* bootstrap break point 01 */
  .section.block-26 .border--round {
    height: 346px;
  }
}
@media (min-width: 768px) {
  /* bootstrap break point 02 */
  .section.block-26 .border--round {
    height: 224px;
  }
}
@media (min-width: 992px) {
  /* bootstrap break point 03 */
  .section.block-26 .border--round {
    height: 305px;
  }
}
@media (min-width: 1200px) {
  /* bootstrap break point 04 */
  .section.block-26 .border--round {
    height: 366px;
  }
}
/* end, block-26 classes */

/* starts, block-27 & block-28 classes */
.section.block-27 .border--round,
.section.block-28 .border--round {
  width: 100%;
  height: 234px;
  margin-bottom: 26px;
  object-fit: cover;
}
@media (min-width: 576px) {
  /* bootstrap break point 01 */
  .section.block-27 .border--round,
  .section.block-28 .border--round {
    height: 346px;
  }
}
@media (min-width: 768px) {
  /* bootstrap break point 02 */
  .section.block-27 .border--round,
  .section.block-28 .border--round {
    height: 224px;
  }
}
@media (min-width: 992px) {
  /* bootstrap break point 03 */
  .section.block-27 .border--round,
  .section.block-28 .border--round {
    height: 305px;
  }
}
@media (min-width: 1200px) {
  /* bootstrap break point 04 */
  .section.block-27 .border--round,
  .section.block-28 .border--round {
    height: 366px;
  }
}
/* end, block-27 & block-28 classes */

/* starts, block-37 classes */
.section.block-37 .slider .slides {
  border: 1px solid #ececec;
  border-radius: 6px;
}
.section.block-37 .slider .slide {
  height: 230px;
  width: 100%;
  background: url() center no-repeat;
  background-size: auto 100%;
  border-width: 0;
}
@media (min-width: 576px) {
  /* bootstrap break point 01 */
  .section.block-37 .slider .slide {
    height: 340px;
  }
}
@media (min-width: 768px) {
  /* bootstrap break point 02 */
  .section.block-37 .slider .slide {
    height: 259px;
  }
}
@media (min-width: 992px) {
  /* bootstrap break point 03 */
  .section.block-37 .slider .slide {
    height: 298px;
  }
}
@media (min-width: 1200px) {
  /* bootstrap break point 04 */
  .section.block-37 .slider .slide {
    height: 360px;
  }
}
/* end, block-37 classes */

/* starts, page-product classes */
section.page-product .slider {
  margin-bottom: 34px;
}
section.page-product .slider .slides {
  border: 1px solid #ececec;
  border-radius: 6px;
}
section.page-product .slider .slide {
  height: 230px;
  width: 100%;
  background: url() center no-repeat;
  background-size: auto 100%;
  border-width: 0;
}
@media (min-width: 576px) {
  /* bootstrap break point 01 */
  section.page-product .slider .slide {
    height: 340px;
  }
}
@media (min-width: 768px) {
  /* bootstrap break point 02 */
  section.page-product .slider .slide {
    height: 299px;
  }
}
@media (min-width: 992px) {
  /* bootstrap break point 03 */
  section.page-product .slider .slide {
    height: 405px;
  }
}
@media (min-width: 1200px) {
  /* bootstrap break point 04 */
  section.page-product .slider .slide {
    height: 485px;
  }
}
/* end, page-product classes */

/* starts, .footer-2 */
.section-footer .nav-button-edit {
  top: calc(50% - 25px);
}

.like-pre {
  white-space: pre;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.modal-overlay .yt-video-container {
  width: 100%;
}

/* starts, section_06 classes */
.section .section_06 .inside-btn-inline {
  padding: 30px 0 0 0;
}
.section .section_06 .inside-btn-inline .btn {
  display: inline-block;
  min-width: auto;
  padding: 0;
}
/* end, section_06 classes */

.delete-item {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
  color: gray;
  opacity: 0.4;
  cursor: pointer;
  background-image: url("../icons/delete.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.delete-item-collapsible {
  top: unset;
}

.overflow-visible {
  overflow: visible;
}

span + input,
span + select,
span + textarea {
  margin-top: 5px;
}

img.banner_logo,
svg.banner_logo {
  max-height: 300px;
  max-width: 100%;
}

svg.banner_logo {
  margin-bottom: 10px;
  width: auto;
}

.font-select .Dropdown-title {
  background-color: #f5f5f5;
  color: #444 !important;
  font-weight: 400 !important;
}

.font-select .Dropdown-option,
.font-select .Dropdown-title {
  font-size: 20px;
}

.rwt__tab {
  padding-left: 20px;
  padding-right: 20px;
  border-bottom-width: 0;
  border-right-width: 0;
  border-color: #ececec;
  color: #666;
}

.rwt__tab:last-child {
  border-right-width: 1px;
}

.rwt__tab.tab-active {
  color: #222;
  font-weight: 600;
}

.rwt__tablist {
  border-bottom-width: 0;
}

.logo-maker-icon {
  float: left;
}
.logo-maker-text:first-line {
  display: inline-block;
}
.logo-maker-text:hover {
  border: 1px;
}

/* starts image picker modal */
.image-picker-modal {
  width: 70% !important;
}
.image-picker-modal .box-images {
  height: calc(100vh - 267px) !important;
  overflow-y: auto;
}
.images-search-icon {
  position: absolute;
  top: 0;
  right: 15px;
  cursor: pointer;
  background-color: #e6e6e6;
  padding: 10px;
  padding-bottom: 5px;
}
.images-search-icon:hover {
  background-color: #f3f3f3;
}
.image-search-thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
  margin-bottom: 4px;
}
.image-search-thumbnail-text {
  white-space: nowrap;
  overflow: hidden;
  padding-left: 4px;
  font-weight: 200;
  font-size: 12px;
}
.type-illustration .tile-img img {
  border: 1px solid #efefef;
}
/* end image picker modal */

.logo-maker-preview {
  padding: 8px;
  height: unset;
}
input.logo-maker-text {
  background-color: transparent;
}

/* starts : sticky-sidebars */

.sticky-sidebars {
}

.sticky-sidebar-rightcol,
.sticky-sidebar-leftcol {
  will-change: min-height;
}

.sticky-sidebars .inner-wrapper-sticky {
  transform: translate(0, 0);
  /* For browsers don't support translate3d. */
  transform: translate3d(0, 0, 0);
  will-change: position, transform;
}

@media (max-width: 767px) {
  .is-affixed .inner-wrapper-sticky {
    position: static !important;
  }
}
/*
.sticky-sidebar-rightcol.is-affixed .sidebar__inner {
    margin-left: 15px;
}
*/
/* end : sticky-sidebars */

/* starts : product blocks */
.product-blocks .buttons {
  margin: 36px -26px 0;
}
.product-blocks .buttons .btn {
  display: block;
  line-height: 39px;
  background-color: white;
  border-color: #f5f5f5;
}
.product-blocks .buttons .btn .btn__text {
  font-size: 1em;
  color: #222;
}
.product-blocks .buttons .btn.btn-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-width: 1px 0 0 0;
}
.product-blocks .buttons .btn.btn-right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-width: 1px 0 0 1px;
}
.product-blocks .buttons .btn:hover {
  transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  background-color: #222;
  border-color: #222;
}
.product-blocks .buttons .btn:hover .btn__text {
  color: white;
}
@media (max-width: 767px) {
  .product-blocks .filters .input-select {
    margin-bottom: 16px;
  }
}
/* end : product blocks */

/* starts : alert-addproduct */
.alert-addproduct {
  background-color: #fa6160 !important;
  border-color: #fa6160 !important;
}
.alert-addproduct .btn {
  border-color: #fa6160 !important;
  color: #fa6160;
}
/* end : alert-addproduct */

/* starts : check out */
.cart-checkout {
}
.cart-customer-details .product-item div[class*="col-"] {
  margin-top: 0;
}
.cart-checkout .product-item + .product-item {
  margin-top: 10px;
  border-top: 1px dashed #ececec;
  padding-top: 10px;
}
.cart-checkout .product-item .price {
  font-size: 15px;
  min-width: 36px;
}
.cart-checkout .product-item * {
  line-height: 1.2;
  word-break: break-all;
}
.cart-checkout .product-item .img {
  width: 48px;
  height: 48px;
  background: url() center no-repeat;
  background-size: contain;
}
.cart-checkout .product-item .title .variant:empty {
  display: none;
}
.cart-checkout .product-item .title .variant {
  display: block;
  color: #666666;
  font-size: 11px;
}
.cart-checkout .product-item .title:hover .variant {
  color: #2275d7;
}
.cart-checkout .input-icon i {
  top: 10px;
  left: 10px;
}
.cart-checkout hr.short {
  margin: 7px 0 14px 0;
}
.cart-checkout .input-number .input-number__controls {
  padding: 0;
}
.cart-checkout .input-number .input-number__controls > span {
  min-width: 32px;
  padding-top: 10px;
}
.cart-checkout .input-number > input[type="number"] {
  padding: 0;
  height: 32px;
  min-width: 96px;
  max-width: 96px;
}
.cart-checkout
  .input-number
  .input-number__controls
  > span.input-number__increase {
  padding-top: 9px;
}
.cart-checkout .delete .material-icons {
  color: #fa6160;
}
.cart-checkout .delete .material-icons:hover {
  text-decoration: none;
}
@media (max-width: 767px) {
  .m-md-b-0 {
    margin: 0;
  }
  .cart-checkout .col-right {
    padding-top: 48px;
  }
  .cart-checkout .row form > .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.input-select::after {
  line-height: 42px;
  top: -0.5px;
}
/* end : check out */

/* starts, desktop header classes */
.d-header {
  /* background-color: green !important; */
}
.d-header.bar {
  padding: 15.5px 0;
  position: relative;
}
.d-header .row {
  /* background-color: red; */
}
.d-header.bar .row.align-items-center .menu-horizontal {
  position: static;
}
.d-header .bar__module.s-logos {
  margin-bottom: 0;
}
.d-header .menu-horizontal > li:not(:hover) > a {
  opacity: 1;
}
.d-header .menu-horizontal > li:hover > a {
  opacity: 0.7;
}
.s-logos .logo-link {
  display: flex;
  width: fit-content;
  /* background-color: black; */
}
.s-logos .logo-link:hover {
  text-decoration: none;
}
.s-logos .logo {
  position: static;
  max-height: none;
}
.s-logos img.logo {
  max-height: 36px;
}

.s-logos img.logo.size-15 {
  max-height: 28px;
}

.s-logos img.logo.size-20 {
  max-height: 36px;
}

.s-logos img.logo.size-30 {
  max-height: 46px;
}

.s-logos img.logo.size-40 {
  max-height: 50px;
}

.s-logos .logo-icon {
  font-size: 30px;
  font-weight: 600;
}
.s-logos .logo-text {
  line-height: 1.2;
  display: inline-block;
}

/* starts, Icon left */
.s-logos .p-icon-left {
}
.s-logos .p-icon-left span.logo-text {
  padding-left: 7px;
}
/* end, Icon left */

/* starts, Icon right */
.s-logos .p-icon-right {
}
.s-logos .p-icon-right span.logo-text {
  padding-right: 7px;
}
/* end, Icon right */

/* starts, Icon top */
.s-logos .p-icon-top {
  text-align: center;
  padding: 4px 0;
}

.s-logos .p-icon-top span.logo-text {
  padding-top: 4px;
}
/* end, Icon right */

/* starts, Icon bottom */
.s-logos .p-icon-btm {
  text-align: center;
  padding: 4px 0;
}
.s-logos .p-icon-btm span.logo-text {
  padding-bottom: 4px;
}
/* end, Icon bottom */

@media all and (max-width: 767px) {
  .d-header.bar .logo.logo-light {
    display: none;
  }
}

/* end, desktop header classes */

/* starts, tablet header classes */
@media (min-width: 768px) {
  .bar-1 .bar__module {
    margin-bottom: 0 !important;
  }
  .bar__module .btn:not(.block) {
    margin: 0.464285715em !important;
  }
  .bar-1 .menu-horizontal > li:not(:first-child) {
    margin-left: 0;
  }
  .bar-1 .menu-horizontal > li:not(:last-child) {
    margin-right: 0.92857143em;
  }
}
/* end, tablet header classes */

/* starts, mobile header classes */
.m-header {
  /* background-color: green; */
}
.m-header .row {
  /* background-color: red; */
}
.m-header.bar.bar--sm {
  padding: 7px 0;
}
.m-header .hamburger-toggle {
  display: inline-block;
  height: 28px;
  width: 22px;
  position: relative;
  transition: 0.3s all;
  -moz-transition: 0.3s all;
  cursor: pointer;
  display: inline-block;
  top: 10px;
  left: -10px;
  margin: 0 1px 0 21px;
}
.m-header .hamburger-toggle:hover {
  text-decoration: none;
}

.m-header .hamburger-toggle span {
  position: absolute;
  left: 0;
  background-color: #252525;
  height: 3px;
  width: 100%;
  transition: 0.3s all;
  -moz-transition: 0.3s all;
  border-radius: 1px;
}

.m-header .hamburger-toggle span:nth-child(2) {
  top: 6.5px;
}

.m-header .hamburger-toggle span:last-child {
  top: 12.5px;
}

.m-header .hamburger-toggle.toggled-class {
  top: 17px;
}

.m-header .hamburger-toggle.toggled-class span:first-child {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}

.m-header .hamburger-toggle.toggled-class span:nth-child(2) {
  height: 2.5px;
  -webkit-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  opacity: 0;
}

.m-header .hamburger-toggle.toggled-class span:last-child {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  top: 0;
}

@media all and (max-width: 767px) {
  .bar-1 .menu-horizontal > li > a,
  .bar-1 .menu-horizontal > li > .dropdown__trigger {
    font-size: 17px;
  }
  .bar-1 .menu-horizontal > li > .dropdown__trigger {
    opacity: 0.5;
  }
  .nav-container .dropdown__container .dropdown__content {
    background-color: transparent !important;
  }
  .nav-container .dropdown__container .dropdown__content .text-truncate {
    overflow: visible;
    white-space: normal;
    font-size: 14px;
    line-height: 1.5;
    padding: 0 0 4px 0;
  }
  .s-header .h-bar.pos-fixed {
    animation: fadeInDown 0.3s ease-out forwards;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
  }
  .s-header .h-bar.pos-fixed {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;
  }
}

/* end, mobile header classes */

/* starts, btn groups to set mobile buttons alignment */
.c-btn-group {
}
@media (max-width: 767px) {
  .c-btn-group {
    display: inline-flex;
    flex-flow: column;
  }
  .c-btn-group .btn {
    display: block;
  }
  .c-btn-group:not(:last-child) {
    margin-bottom: 1.85714286em;
  }
}
.c-btn-group + p.type--fine-print,
.c-btn-group + span.type--fine-print {
  margin-top: 1.08333333333334em;
}
/* end, btn groups to set mobile buttons alignment */

/* starts, editable blocks rules */
.section.editable-block a.link-text .edit {
  right: -18px;
  top: -18px;
}
.section.editable-block .textarea-edit.span {
  font-size: 14px;
}
.section.editable-block .textarea-edit.h5 {
  margin: 0;
}
/* end, editable blocks rules */

/* card input styles */
.card-input-container iframe {
  min-height: unset;
}

.card-input-container {
  background: #fcfcfc;
  padding: 0.46428571em;
  border-radius: 6px;
  border: 1px solid #ececec;
  height: 45px;
  justify-content: center;
}

.disable-view {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 28px;
  color: #333;
  font-weight: 600;
}
.disable-view .spinner {
  top: -45px;
  position: relative;
}
.disable-view .logo-svg {
  width: 240px;
  height: 240px;
  top: 31px;
  position: relative;
  overflow: hidden;
}

.shimmer {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

.cart-checkout i.material-icons {
  color: #c3c3c3;
}

.google-suggestion-item {
  cursor: pointer;
  padding: 5px;
  padding-left: 8px;
  font-size: 12px;
  margin: 0px;
}

.google-suggestion-item:hover {
  background-color: #f5f5f5;
}

.google-suggestion-list {
  box-shadow: 0 0.4rem 0.5rem 0.0625rem #dbdbdc !important;
}

.google-suggestion-item-manual {
  color: #fa6160;
  text-decoration: underline;
}

.country-select,
.shipping-method-select {
  padding-left: 45px;
}

/* starts : Header icon-checkout */
a.nav-checkout {
  color: #444248;
  position: relative;
  z-index: 0;
  top: 0px;
  color: white;
  font-size: 12px;
  line-height: 38px;
  font-weight: 700;
  z-index: 0;
  display: inline-block;
  text-align: center;
  width: 38px;
  height: 38px;
  margin-left: 3.5px;
}
a.nav-checkout svg {
  width: 46px;
  height: 46px;
  position: absolute;
  z-index: -1;
  left: -4px;
  top: -4px;
}
a.nav-checkout span {
  position: relative;
  z-index: 0;
  top: 5px;
  display: inline-block;
}
a.nav-checkout:hover {
  text-decoration: none;
}
.m-header a.nav-checkout {
  margin-left: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.m-header a.nav-checkout svg {
  width: 34px;
  height: 34px;
  left: -2px;
  top: -2px;
}
.m-header a.nav-checkout span {
  top: 4px;
}
/* end : Header icon-checkout */

/* starts : checkout-bag */
.co-bag {
  display: inline;
  position: relative;
  z-index: 10;
}
.co-bag .co-bag-dropdown-bg {
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  height: 100vh;
  display: none;
}
.co-bag.show-bag-dropdown .co-bag-dropdown-bg {
  display: block;
}
.co-bag .co-bag-dropdown {
  position: absolute;
  top: 74px;
  width: 325px;
  left: -281px;
  border-radius: 6px;
  opacity: 0;
  transition: opacity 0.5s;
  -webkit-box-shadow: 0 0 7px 1px rgba(45, 45, 45, 0.14);
  box-shadow: 0 0 7px 1px rgba(45, 45, 45, 0.14);
}
.co-bag .co-bag-dropdown.hide {
  display: none;
}
.co-bag.show-bag-dropdown .co-bag-dropdown {
  opacity: 1;
}

.co-bag .main {
  min-height: 194px;
  max-height: calc(100vh - 200px);
  text-align: left;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  background-color: #fff;
  border-radius: 6px;
}

.co-bag .head {
  height: 50px;
  color: black;
  padding-right: 7px;
  flex-shrink: 0;
}

.co-bag .head .close {
  width: 30px;
  height: 30px;
  color: black;
  cursor: pointer;
  /* background-image: url('../icons/delete.png');
  background-position:center center;
  background-repeat:no-repeat;
  background-size: contain; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.co-bag .bottom {
  padding: 12px 15px;
}
.co-bag .items {
  padding: 12px 15px;
  overflow: auto;
}
.co-bag .items .item {
  padding: 14px 0;
  color: black;
}
.co-bag .items .item * {
  line-height: 1.2;
  word-break: break-all;
}
.co-bag .items .item + .item {
  border-top: 1px solid #eee;
}
.co-bag .items .item .img {
  width: 108px;
  max-height: 108px;
  background: url() center top no-repeat;
  background-size: contain;
}
.co-bag .items .item .price {
  padding-top: 4px;
  padding-bottom: 12px;
  font-weight: 600;
}
.co-bag .items .item .title {
  font-weight: 600;
  font-size: 12px;
}
.co-bag .items .item .variant {
  font-size: 11px;
  padding-bottom: 4px;
  color: #666;
}
.co-bag .items .item .quantity {
  padding-top: 4px;
  padding-bottom: 2px;
  font-size: 12px;
}
.co-bag .items .item .delete .material-icons {
  color: #fa6160;
  text-decoration: none;
  font-size: 18px;
}
.co-bag .items .item .delete .material-icons:hover {
  text-decoration: none;
}

.co-floating-countdown {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background: #000;
  z-index: 999;
  color: #fff;
  font-size: 16px;
  line-height: 1.25;
  border-radius: 50%;
  text-align: center;
  padding: 1rem;
  min-width: 110px;
  min-height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.co-floating-countdown:hover {
  cursor: pointer;
}

/* end : checkout-bag */

.free-shipping-label {
  background-color: #37db70 !important;
}

/* starts, block-41 classes */
.section.block-41 .inside-btn-inline {
  padding: 30px 0 0 0;
}
.section.block-41 .inside-btn-inline .btn {
  display: inline-block;
  min-width: auto;
  padding: 0;
}
.section.block-41 .countdown {
  margin-bottom: 0.59090909090909em;
}
.section.block-41 .video-play {
  margin: 2.78571429em 0;
}
.section.block-41 .banner-h1 {
  margin-bottom: 12px;
}
.section.block-41 .banner-h1.fs-xl {
  font-size: 5em;
}
.section.block-41 .banner-h1.fs-lg {
  font-size: 4.5em;
}
.section.block-41 .banner-h1.fs-md {
  font-size: 4em;
}
.section.block-41 .banner-h1.fs-sm {
  font-size: 3.5em;
}
.section.block-41 .banner-h1.fs-xs {
  font-size: 3em;
}
@media all and (max-width: 767px) {
  /* 20% smaller size */
  .section.block-41 .banner-h1.fs-xl {
    font-size: 4em;
  }
  .section.block-41 .banner-h1.fs-lg {
    font-size: 3.6em;
  }
  .section.block-41 .banner-h1.fs-md {
    font-size: 3.2em;
  }
  .section.block-41 .banner-h1.fs-sm {
    font-size: 2.8em;
  }
  .section.block-41 .banner-h1.fs-xs {
    font-size: 2.4em;
  }
}
.section.block-41 .banner-h1 + .lead {
  position: relative;
  top: -5.5px;
  z-index: 0;
}
.section.block-41 .yt-container {
  overflow: hidden;
}
.section.block-41 .yt-container.background-image-holder {
  display: none !important;
}
.section.block-41.height-half img.banner_logo,
.section.block-41.height-half svg.banner_logo {
  max-height: 150px;
}
.section.block-41 .c-btn-group {
  margin-top: -0.92857143em !important;
  margin-bottom: -0.92857143em !important;
}
.section.block-41 .c-btn-group .btn {
  margin: 0.92857143em !important;
}
@media all and (max-width: 767px) {
  .section.block-41 .c-btn-group:not(:last-child) {
    margin-bottom: 0.92857143em !important;
  }
}
@media (max-width: 1024px) {
  .section.block-41 .yt-container {
    display: none;
  }
  .section.block-41 .yt-container.background-image-holder {
    display: block !important;
  }
  .section.block-41[data-gradient-bg] .yt-container.background-image-holder {
    opacity: 0.2 !important;
  }
}
.section.block-41 a.link-text {
  color: #fff;
  font-weight: 600;
}
/* end, block-41 classes */

/* starts, block-42 classes */
.section.block-42 label.MuiInputLabel-root {
  margin-top: 0;
}
/* end, block-42 classes */

/* starts, block-46 classes */
.section.block-46 h4,
.section.block-46 .textarea-edit.h4 {
  margin-top: 1.85714286em;
  font-size: 1.3em;
  line-height: 1.85714286em;
  margin-bottom: 0;
  font-weight: 600;
}
/* end, block-46 classes */

/* starts, block-47 classes */
.section.block-47 .img-container img {
  height: 360px;
  width: 100%;
  object-fit: cover;
}
/* end, block-47 classes */

/* starts, block-50 classes */
.section.block-50 .c-btn-group {
  display: inline-flex;
  flex-flow: column;
}
.section.block-50 .c-btn-group .btn {
  display: block;
}
.section.block-50 .c-btn-group .btn:first-child {
  margin-bottom: 1.85714286em;
}
.section.block-50 .c-btn-group .btn + .btn {
  margin-left: 0;
}
.section.block-50 .video-play {
  margin: 2.78571429em 0 2.507142861em 0;
}
.section.block-50 .video-play-icon {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
  -webkit-box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
}
.section.block-50 .inside-btn-inline {
  padding: 30px 0 0 0;
}
.section.block-50 .inside-btn-inline .btn {
  display: inline-block;
  min-width: auto;
  padding: 0;
}
.section.block-50 svg {
  height: auto;
  max-width: 100%;
}
.section.block-50 img {
  max-height: 300px;
  width: auto;
}
@media all and (min-width: 767px) {
  .section.block-50.height-half img,
  .section.block-50.height-half svg {
    max-height: 340px;
  }
}
/* end, block-50 classes */

/* starts, block-53 classes */
.section.block-53 .feature-7 {
  height: 26em;
}
@media all and (max-width: 767px) {
  .section.block-53 .pos-vertical-center {
    padding: 0 22px;
  }
}
/* end, block-53 classes */

h1.banner-h1,
.h1.banner-h1 {
  font-size: 4.5em;
}

.h1-font-size {
  font-size: 3.14285714em;
  line-height: 1.31818182em;
}

.grecaptcha-badge {
  visibility: hidden;
}

.tour-mask-hidden {
  color: transparent;
}

.tour-mask-visible {
  color: rgba(0, 0, 0, 0.15);
}

.tour-step h3 {
  color: #37db70;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 1.46em;
}

.tour-step h3,
.tour-step p {
  line-height: 1.1;
}

.tour-step p {
  font-size: 15px;
}

span[data-tour-elem="badge"] {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  font-family: sans-serif;
}
.tour-step-badge {
  color: white;
  font-size: 22px;
  line-height: 1.4;
}

nav[data-tour-elem="navigation"] {
  margin-top: 15px;
}

/* starts : Tour step */
.tour {
  border-radius: 9px !important;
}
.tour-step .material-icons {
  position: absolute;
  top: 5px;
  left: -36px;
  font-size: 60px;
  font-weight: bold;
  color: #37db70;
}
.tour-step.arrow_drop_down .material-icons {
  bottom: -36px;
  right: 5px;
  left: auto;
  top: auto;
}
.tour-step.arrow_right .material-icons {
  top: 6px;
  right: -36px;
  left: auto;
}
.tour-step.arrow_drop_up .material-icons {
  top: -36px;
  right: 5px;
  left: auto;
}
.tour-nav-button {
  font-size: 16px;
  line-height: 1.3;
  font-weight: 100;
  height: 30px;
  color: #333;
}
div[data-tour-elem="controls"] button[data-tour-elem="left-arrow"],
div[data-tour-elem="controls"] button[data-tour-elem="right-arrow"] {
  width: 60px;
  padding: 5px;
  border-radius: 5px;
  border: solid;
  border-width: 1px;
  height: 33px;
  margin-top: 8px;
  margin-left: 12px;
  margin-right: 12px;
  border-color: #333;
}
div[data-tour-elem="controls"] button[data-tour-elem="left-arrow"][disabled],
div[data-tour-elem="controls"] button[data-tour-elem="right-arrow"][disabled] {
  border-color: #e5e5e5;
}
div[data-tour-elem="controls"]
  button[data-tour-elem="left-arrow"][disabled]
  .tour-nav-button,
div[data-tour-elem="controls"]
  button[data-tour-elem="right-arrow"][disabled]
  .tour-nav-button {
  color: #e5e5e5;
}
div[data-tour-elem="controls"] {
  justify-content: center !important;
}
/* end : Tour step */
.tags-v1 {
  padding: 15px 0 0;
}
.tags-v1 span.tag {
  background: #f2f2f2;
  display: inline-block;
  padding: 6px 15px 7px;
  margin: 0 4px 10px 0;
  border-radius: 6px;
  font-size: 1em;
  line-height: 21px;
  color: #666;
  font-weight: 600;
  border-color: #ececec;
  font-size: 12px;
}

.btn-group-not-found {
  margin-top: 0px;
}

.btn-group-not-found .btn {
  font-size: 24px;
}

.btn-group-not-found .btn-primary {
  background-color: white;
  color: #009ee5;
  border: none;
}

.btn-group-not-found .btn-secondary {
  color: white;
}

/*
span.label-msc-enabled {
  filter: brightness(80%);
}
span.label-msc-enabled > p {
  filter: brightness(120%);
}
*/

/* starts contextMenu */
#contextMenu {
  /* visibility: visible !important;
  top:25% !important;
  left:50%;
  position: fixed !important; */
  z-index: 1002;
  background-color: rgba(0, 0, 0, 0.6) !important;
  border-color: rgba(0, 0, 0, 0.6) !important;
  border-radius: 6px !important;
  border-width: 0px !important;
  box-shadow: none !important;
  padding: 10px 25px !important;
  top: 0;
}
#contextMenu .menuItem:first-child {
  font-weight: 600 !important;
  font-size: 16px !important;
  margin-bottom: 6px !important;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3) !important;
  cursor: text !important;
}
#contextMenu .menuItem:last-child {
  border-top: 1px dashed rgba(255, 255, 255, 0.3) !important;
  margin-top: 6px !important;
}
#contextMenu .menuItem {
  color: #ffffff !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  font-weight: 500 !important;
  padding: 4px 0 !important;
  margin: 0 !important;
}
#contextMenu .menuItem:hover:not(:first-child) {
  color: rgba(255, 255, 255, 0.7) !important;
}
/* end contextMenu */

/* starts : modal share */

.react-share__ShareButton {
  padding-right: 5px !important;
}
.modal-share {
  margin: -2.78571429em;
}
.modal-share .modal-head {
  padding: 20px 23px;
  border-bottom: 1px solid #ecf0f3;
  line-height: normal;
}
.modal-share .modal-body {
  padding: 1.75rem;
}
.modal-share .modal-footer {
  padding: 1.5rem;
  border-top: 1px solid #ecf0f3;
}
.modal-share .input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.modal-share .input-group > .form-control {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.modal-share .input-group > .form-control#shareLink {
  min-height: 41px;
}
.modal-share .input-group > .form-control:focus {
  z-index: 3;
}
.modal-share .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.modal-share .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-share .input-group-prepend,
.modal-share .input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.modal-share .input-group-prepend .btn,
.modal-share .input-group-append .btn {
  position: relative;
  z-index: 2;
}
.modal-share .input-group-prepend .btn:focus,
.modal-share .input-group-append .btn:focus {
  z-index: 3;
}
.modal-share .input-group-prepend .btn + .btn,
.modal-share .input-group-prepend .btn + .input-group-text,
.modal-share .input-group-prepend .input-group-text + .input-group-text,
.modal-share .input-group-prepend .input-group-text + .btn,
.modal-share .input-group-append .btn + .btn,
.modal-share .input-group-append .btn + .input-group-text,
.modal-share .input-group-append .input-group-text + .input-group-text,
.modal-share .input-group-append .input-group-text + .btn {
  margin-left: -1px;
}
.modal-share .input-group-prepend {
  margin-right: -1px;
}
.modal-share .input-group-append {
  margin-left: -1px;
}
.modal-share .input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.65rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #464e5f;
  text-align: center;
  white-space: nowrap;
  background-color: #f3f6f9;
  border: 1px solid #e5eaee;
  border-radius: 0.42rem;
}
.modal-share .input-group-text input[type="radio"],
.modal-share .input-group-text input[type="checkbox"] {
  margin-top: 0;
}
.modal-share .input-group > .input-group-prepend > .btn,
.modal-share .input-group > .input-group-prepend > .input-group-text,
.modal-share .input-group > .input-group-append:not(:last-child) > .btn,
.modal-share
  .input-group
  > .input-group-append:not(:last-child)
  > .input-group-text,
.modal-share
  .input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.modal-share
  .input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.modal-share .input-group > .input-group-append > .btn,
.modal-share .input-group > .input-group-append > .input-group-text,
.modal-share .input-group > .input-group-prepend:not(:first-child) > .btn,
.modal-share
  .input-group
  > .input-group-prepend:not(:first-child)
  > .input-group-text,
.modal-share
  .input-group
  > .input-group-prepend:first-child
  > .btn:not(:first-child),
.modal-share
  .input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-share #copyButton,
.modal-share #add-success {
  padding-left: 13px;
  padding-right: 13px;
  min-height: 41px;
}
.modal-share .form-group label {
  font-size: 1rem;
  font-weight: 400;
  color: #464e5f;
  padding-bottom: 3px;
}
.modal-share .btn.btn-primary {
  background-color: #333;
  border-color: #333;
  color: #fff;
}
.modal-share .btn.btn-primary:hover {
  transform: none;
}
.modal-share .btn.btn-secondary {
  background-color: transparent;
  border: 1px solid #e2e5ec;
  color: #595d6e;
}
/* end : modal share */

.site-wide-notice {
  -webkit-animation: slidein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: slidein 1s; /* Firefox < 16 */
  -ms-animation: slidein 1s; /* Internet Explorer */
  -o-animation: slidein 1s; /* Opera < 12.1 */
  animation: slidein 2s forwards 10s;
  margin-top: -50px;
}
@keyframes slidein {
  from {
    margin-top: -50px;
  }
  to {
    margin-top: 0px;
  }
}

/* section:not(.block-42),
section.d-flex:not(.block-42){
  display: none !important;
} */

/* starts upload my font */
.upload-my-font {
  position: relative;
  z-index: 0;
  overflow: hidden;
  /* background-color: red; */
  max-height: 42px;
}
.upload-my-font .btn {
  margin: 0;
  background-color: #333;
  border-color: #333;
}
.upload-my-font .btn,
.upload-my-font .btn span {
  color: #fff;
}
.upload-my-font input {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
/* end upload my font */

.Mui-error {
  color: #fa6160 !important;
}

/* starts, form CRM */
.form-crm {
}
.form-crm .MuiFormLabel-root {
  color: inherit;
}
.form-crm .MuiOutlinedInput-input {
  padding: 18.5px 14px !important;
  border: 0;
}
.form-crm .MuiOutlinedInput-multiline {
  padding: 0 !important;
  background: #fcfcfc;
}
/* end, form CRM */

/* starts, react-virtualized customisation  */
.r-grid-height {
  height: calc(100vh - 290px) !important;
}
/* end, react-virtualized customisation  */

/* starts, partner classes */
.list-benefits li {
  position: relative;
  z-index: 0;
  padding: 0 0 5px 38px;
  font-size: 16px;
  font-weight: 600;
}
.list-benefits li .symbol {
  position: absolute;
  top: 2px;
  left: 0;
}
/* end, partner classes */

/* starts, Button classes  */
.btn {
}
.btn.btn--sm.btn--icon {
  padding-left: 69px;
  padding-right: 26px;
}
.btn--icon i:not(.edit-button-i) {
  line-height: normal;
  display: flex;
  align-items: center;
}
.btn.btn--icon .edit-button i.icon {
  background: none !important;
}
.icons-grid {
  line-height: normal;
}
/* end, Button classes  */

/* starts, h-hamburger */
nav.bar--transparent.h-hamburger {
  position: relative;
}
.menu-fullscreen .nav-block {
  margin-bottom: 3.71428571em;
}
.menu-fullscreen .modal-content {
  width: 100% !important;
  padding: 24px 0;
}
.menu-fullscreen .menu-horizontal a {
  border: 1px solid #fff;
  display: inline-block;
  padding: 3px 18px;
  border-radius: 4px;
}
.menu-fullscreen .menu-horizontal > li:not(:hover) > a,
.menu-fullscreen .menu-horizontal > li:not(:hover) > span,
.menu-fullscreen
  .menu-horizontal
  > li:not(:hover)
  > .modal-instance
  > .modal-trigger,
.menu-fullscreen .menu-horizontal li:not(:hover):not(.dropdown--active) {
  opacity: 1;
}
.menu-fullscreen
  .menu-horizontal
  > li.dropdown.dropdown--active
  .dropdown__container {
  position: static;
}
.menu-fullscreen .dropdown .dropdown__container {
  transition: none;
}
.menu-fullscreen .dropdown .dropdown__container:before {
  height: 18px;
  width: 100%;
  content: "";
  display: block;
}
.menu-fullscreen
  .menu-horizontal
  > li.dropdown.dropdown--active
  .dropdown__content:not([class*="bg-"]) {
  background-color: transparent;
  border: 1px solid #fff;
}
.menu-fullscreen
  .menu-horizontal
  > li.dropdown.dropdown--active
  .dropdown__content {
  margin: 0 auto;
  position: static;
}
.menu-fullscreen .menu-horizontal .menu-vertical {
  text-align: center;
}
.menu-fullscreen .menu-horizontal .menu-vertical li {
  margin-right: 0;
}
.menu-fullscreen .menu-vertical li:not(:last-child) {
  margin-bottom: 8px;
}
.menu-fullscreen .menu-horizontal > li {
  display: block;
  margin-right: 0;
}
.menu-fullscreen .menu-horizontal > li:not(:last-child) {
  margin-bottom: 13px;
}
.menu-fullscreen:before {
  background: rgba(0, 0, 0, 0.8);
}

.menu-fullscreen.modal-container .modal-content .container {
  width: auto;
}
.menu-fullscreen .menu-horizontal a {
  min-width: 250px;
  background-color: #fff;
  color: #252525 !important;
}
.menu-fullscreen .menu-horizontal > li.dropdown.dropdown--active {
  min-width: 250px;
}
.menu-fullscreen .menu-horizontal > li.dropdown.dropdown--active a {
  border: 1px solid #fff;
}
.menu-fullscreen .dropdown .dropdown__container:before {
  display: none;
}
.menu-fullscreen
  .menu-horizontal
  > li.dropdown.dropdown--active
  .dropdown__content:not([class*="bg-"]) {
  border-width: 0;
  padding: 8px 13px 0 13px;
  width: 100%;
}
.menu-fullscreen .menu-horizontal > li.dropdown .dropdown__trigger {
  position: relative;
}
.menu-fullscreen .menu-horizontal > li.dropdown .dropdown__trigger::after {
  font-size: 16px;
  position: absolute;
  right: 10px;
  bottom: 3px;
}
.menu-fullscreen .menu-horizontal .menu-vertical li a {
  width: 100%;
  min-width: auto;
  border: 1px solid #fff !important;
}
.menu-fullscreen .btn {
  min-width: 200px;
}

.menu-fullscreen .modal-content {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .menu-fullscreen .modal-content {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .menu-fullscreen .modal-content {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .menu-fullscreen .modal-content {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .menu-fullscreen .modal-content {
    max-width: 1140px;
  }
}
.menu-fullscreen .modal-content .modal-close-cross {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
  width: 3.71428571em;
  height: 3.71428571em;
  border-radius: 50%;
  text-align: center;
  background: #fff;
  display: inline-block;
  top: 25px;
  right: 16px;
  color: #252525;
  opacity: 1;
  line-height: normal;
}
.menu-fullscreen.modal-container .modal-content .modal-close-cross:before {
  font-size: 36px;
  font-weight: bold;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
@media (max-width: 767px) {
  .nav-button-edit {
    display: none;
  }
  .menu-fullscreen.modal-container .modal-content {
    height: 100% !important;
  }
  .menu-fullscreen .modal-content .modal-close-cross {
    top: 23px;
  }
  .menu-fullscreen.modal-container .modal-content .btn + .btn {
    margin-left: 0 !important;
  }
}
.menu-fullscreen.modal-container .menu-horizontal {
  display: inline-block;
}
/* end, h-hamburger */

/* starts, Shimmer css classes */
.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 800px;
  display: block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}
.shine-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
/* end, Shimmer css classes */

/* start: coupon*/
.coupon-applied {
  color: #37db70;
  font-weight: 600;
}
.coupon-valid {
  color: #37db70;
}
/* end: coupon */

/*** Editor Styles ***/
.rdw-link-modal::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

.rdw-link-modal ::-webkit-scrollbar-track {
  border-radius: 5px;
  box-shadow: inset 0 0 5px grey;
}

.rdw-link-modal::-webkit-scrollbar-thumb {
  background: rgb(211, 211, 211);
  border-radius: 5px;
}

.rdw-link-modal::-webkit-scrollbar-thumb:hover {
  background: rgb(226, 225, 225);
}

.rdw-link-modal-input,
.rdw-link-modal-label,
.rdw-link-modal-buttonsection {
  color: black !important;
}

.rdw-link-modal-buttonsection button {
  border-radius: 0.42em;
}

.rdw-link-decorator-wrapper img {
  background: none !important;
}

/*** It will remove the space in icon info box - between p tag and link ***/
.feature-3 p {
  min-height: 0 !important;
}

/*** Chats ***/

.sender-info {
  color: #48465b;
  font-weight: 500;
  font-size: 1.1rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.message-date {
  color: #74788d;
  font-weight: 400;
  font-size: 0.9rem;
  margin-top: 2.95px;
  font-family: Poppins, Helvetica, sans-serif;
  transform: translateY(-0.2rem);
}

.message-row {
  display: flex;
}

.justify-text-end {
  justify-content: end;
}

.justify-text-start {
  justify-content: start;
}

/*** Full Screen ***/

.full-screen {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.react-photo-gallery--gallery img {
  object-fit: cover;
}

/*** Video Gallery ***/
.top-right {
  position: absolute;
  top: 5px;
  right: -10px;
}

/*** Horizontal Line ***/

.full-width-hr {
  position: absolute;
  top: 480px;
  right: 0px;
  width: 100vw;
}

.pagination {
  display: -webkit-flex;
  display: flex;
  padding: 20;
  list-style: none;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.page-item {
  padding: 5px 10px 5px 10px;
  font-weight: 600;
  font-size: 18px;
}

.page-item.active {
  font-weight: 900;
}
.react-js-pagination .page-item.active {
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  margin-right: 10px;
  margin-left: 5px;
}
