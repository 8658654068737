//
// Common classes
//

body.disable-scroll {
  overflow: hidden;
}

// link-share
.link-share{
  .r-box{
    border-radius: 50%;
    padding: 3px;
    font-size: 16px;
    line-height: 1;
    margin-right: 6px;
    color: #fff;
  }
}

// Modal
.modal-regular .modal-content .boxed {
  animation:300ms ease 0s 1 normal none running showSweetAlert;
}

@keyframes showSweetAlert {  
  0% {    
    transform: scale(0.7);    -webkit-transform: scale(0.7);  
  }  
  45% {    
    transform: scale(1.05);    -webkit-transform: scale(1.05);  
  }  
  80% {    
    transform: scale(0.95);    -webkit-tranform: scale(0.95);  
  }  
  100% {    
    transform: scale(1);    -webkit-transform: scale(1);  
  }
}

// Font sizes
@media (min-width: 767px){
  .fs-md-30{
    font-size: 30px;
  }
}

.lh-1{
  line-height: 1;
}

// overflow
.of-visible {
  overflow: visible !important; 
}

// Lazy loading CSS background images
.lazy-background{
  &:not(.lazy-visible) {
      background-image: none !important
  }
}

// social list
.social-list {
  .svg-socicon {
    display: flex;
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 0;
    top: 1px;
  }
}